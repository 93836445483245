<template>
  <div class="section3" id="section3">
    <!-- <div class="route"></div> -->
    <div class="route0">
      <!-- <img src="@/assets/flag.webp" alt=""> -->
      <h1>Alur Pelaksanaan</h1>
    </div>
    <Section :textSection="dynamicComponent" :imgPath="img1" :iconPath="icon1" />
    <SectionB :textSection="dynamicComponent2" :imgPath="img2" :iconPath="icon2" />
    <Section :textSection="dynamicComponent3" :imgPath="img3" :iconPath="icon3" />
    <SectionB :textSection="dynamicComponent4" :imgPath="img4" :iconPath="icon4" />
    <SectionC :textSection="dynamicComponent5" :iconPath="icon3" />
    <!-- <div class="lastword">
      <p>informasi terkait pembayaran dan daftar ulang dapat dilihat di dashboard hasan.</p>
    </div> -->

  </div>
</template>
<script>
const Section = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/section')
const SectionB = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/sectionB')
const SectionC = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/sectionC')
const TextSection = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/dynamicComponent/route1/text')
const TextSection2 = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/dynamicComponent/route2/text')
const TextSection3 = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/dynamicComponent/route3/text')
const TextSection4 = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/dynamicComponent/route4/text')
const TextSection5 = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/dynamicComponent/route5/text')
export default {
  components: {
    Section,
    SectionB,
    SectionC,
    // TextSection,
  },
  data() {
    return {
      dynamicComponent: TextSection,
      dynamicComponent2: TextSection2,
      dynamicComponent3: TextSection3,
      dynamicComponent4: TextSection4,
      dynamicComponent5: TextSection5,
      img1: require('@/assets/imgno1.webp'),
      img2: require('@/assets/imgno2.webp'),
      img3: require('@/assets/imgno3.webp'),
      img4: require('@/assets/imgno4.webp'),
      icon1: require('@/assets/no1.webp'),
      icon2: require('@/assets/no2.webp'),
      icon3: require('@/assets/no3.webp'),
      icon4: require('@/assets/no4.webp'),
    }
  },
}
</script>
<style scoped>
.section3 {
  margin-top: 50px;
  /* padding-top: 50px; */
  padding: 50px;
  /* background-color: aqua; */
  /* padding-left: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;

}

.lastword {
  text-align: start;
  margin-top: 50px;
}

.route {
  /* width: 5px; */
  /* height: 2000px; */
  background-color: #357C2C;
  /* margin-left: 200px; */
  position: absolute;
  z-index: 1;
  left: 0;
}

.route0 {
  display: flex;
}

.route0 img {
  height: 96px;
  /* width: 96px; */
  z-index: 2;
}

.route0 h1 {
  color: #357C2C;
  font-family: Raleway;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  /* margin-left: 40px; */
  text-align: center;
  /* 52.5px */
}

@media screen and (max-width: 560px) {
  .route0 {
    /* background-color: aqua; */
    align-items: center;
    justify-content: center;
  }

  .route0 img {
    height: 80px;
  }

  .route0 h1 {
    font-size: 25px;
    text-align: left;
  }
}
</style>
